let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-nsr-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-nsr-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://seq3m5omvvg2lnujpw7ucafx4e.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://1iz7dkedc8.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://7kasohlprl.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.nsr.forwoodsafety.com",
        WEBSOCKET: "wss://31o1517wr7.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_6nWmGigyH",
        APP_CLIENT_ID: "1mpcnqjqg0f4kim25l39mpgnpd",
        IDENTITY_POOL_ID: "ap-southeast-2:dcc48410-ae6b-4287-bba9-3052c70c4668",
        USERPOOL_HOSTED_DOMAIN: "forwood-nsr-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.nsr.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.nsr.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::387090577908:role/prod-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'Nsr',
      SUPPORTED_LIST: 'Nsr,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.3",
        HOSTNAME: "id.nsr.forwoodsafety.com",
        COOKIE_DOMAIN: ".nsr.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.nsr.forwoodsafety.com"
    }
};

export default config;
